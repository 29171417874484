import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { InfoIconComponent } from '../controls/info-icon/info-icon.component';
import { DatePickConfig, FormSection } from '../../../models/form.config';
import { SavingButtonComponent } from '../../buttons/saving-button/saving-button.component';
import { CancelButtonComponent } from '../../buttons/cancel-button/cancel-button.component';
import { TranslateModule } from '@ngx-translate/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MandatoryFieldIndicatorComponent } from '../controls/mandatory-field-indicator/mandatory-field-indicator.component';
import {
  Observable,
  Subject,
  Subscription,
  catchError,
  debounceTime,
  distinctUntilChanged,
  of,
  switchMap,
} from 'rxjs';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';
import { FormErrorComponent } from '../form-error/form-error.component';
import { SingleFileUploadComponent } from '../controls/single-file-upload/single-file-upload.component';
import { MultipleFileUploadComponent } from '../controls/multiple-file-upload/multiple-file-upload.component';
import { SideFormButtonGroupComponent } from '../side-form-button-group/side-form-button-group.component';
import { MainLoaderComponent } from '../../loaders/main-loader/main-loader.component';
import { DatePickerComponent } from '../controls/date-picker/date-picker.component';
import { getStatusLabelClass } from '../../../../core/modals/status.model';
import { AppSettingsStore } from '../../../../core/store/app-settings.store';
import { OrganizationLevelDetailsDTO } from '../../../../features/settings/general-settings/dto/organization-level.dto';
import { OrganizationUnitService } from '../../../../features/settings/general-settings/services/organization-unit.service';
import { runInAction, toJS } from 'mobx';
import { OrganiztionUnitConversion } from '../../../../features/settings/general-settings/conversion/organization-unit.conversion';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-side-form',
  standalone: true,
  imports: [
    InfoIconComponent,
    SavingButtonComponent,
    CancelButtonComponent,
    TranslateModule,
    MandatoryFieldIndicatorComponent,
    ReactiveFormsModule,
    NgSelectModule,
    CommonModule,
    FormsModule,
    FormErrorComponent,
    SingleFileUploadComponent,
    MultipleFileUploadComponent,
    SideFormButtonGroupComponent,
    MainLoaderComponent,
    DatePickerComponent,
  ],
  templateUrl: './side-form.component.html',
  styleUrl: './side-form.component.scss',
})
export class SideFormComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() buttonPosition: string = 'top';
  @Input() submitButtonTitle: string;
  @Input() submitButtonLoadingText: string;
  @Input() submitButtonAndNewTitle: string;
  @Input() submitButtonAndNewLoadingTitle: string;
  @Input() cancelButtonTitle: string;
  @Input() formGroup: FormGroup;
  @Input() formSections: FormSection[];
  @Input() isLoading: boolean;
  @Input() isOrganizationLevels: boolean;

  @Output() formSubmitRequest = new EventEmitter<boolean>();
  @Output() closeRequest = new EventEmitter();
  subscriptions: Subscription[] = [];
  organizationLevels: OrganizationLevelDetailsDTO[] = [];
  dropdownOptions: { [key: number]: any[] } = {};

  public typeaheadInputs: Map<string, Subject<string>> = new Map();
  public options$: Map<string, Observable<any[]>> = new Map();

  private destroy$ = new Subject<void>(); // Subject to handle unsubscription

  constructor(
    private appSettingsStore: AppSettingsStore,
    private organizationUnitService: OrganizationUnitService,
    private organiztionUnitConv: OrganiztionUnitConversion
  ) {}

  ngOnInit() {
    this.setupFormFields();

    if (this.isOrganizationLevels) {
      this.getOrganizationLevels();
      this.valueChanges();
    }
  }

  setupFormFields() {
    this.formSections.forEach((section) => {
      section.fields.forEach((field) => {
        if (!this.formGroup.contains(field.formControlName)) {
          this.formGroup.addControl(
            field.formControlName,
            new FormControl('', this.bindValidations(field.validators))
          );
        }

        if (field.type === 'autocomplete') {
          const control = new FormControl();
          this.formGroup.addControl(field.formControlName, control);

          const input$ = new Subject<string>();
          this.typeaheadInputs.set(field.formControlName, input$);

          const options$ = input$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            switchMap((value) => this.loadOptions(value, field)),
            catchError((error) => {
              console.error('Error in autocomplete search', error);
              return of([]);
            })
          );
          this.options$.set(field.formControlName, options$);
        }
      });

      // Check if section has organization levels
      if (section.isOrgannizationLevels) {
        this.organizationLevels.forEach((organizationLevel) => {
          const formControlName = `organizastion_unit_id_${organizationLevel.level}`;

          // Add form control for organization levels if it doesn't already exist
          if (!this.formGroup.contains(formControlName)) {
            this.formGroup.addControl(
              formControlName,
              new FormControl('') // Assuming you want to bind section validators here
            );
          }
        });
      }
    });
  }

  onTypeahead(searchText: string, formControlName: string) {
    const input$ = this.typeaheadInputs.get(formControlName);
    if (input$) {
      input$.next(searchText);
    }
  }

  loadOptions(value: string, field: any): Observable<any[]> {
    if (
      !value ||
      !field.serviceMethod ||
      typeof field.service[field.serviceMethod] !== 'function'
    ) {
      return of([]);
    }
    return field.service[field.serviceMethod]('', '', value);
  }

  bindValidations(validators: string[]): any[] {
    const validList = [];
    if (validators.includes('required')) {
      validList.push(Validators.required);
    }
    if (validators.includes('email')) {
      validList.push(Validators.email);
    }
    return validList;
  }

  requestClose() {
    this.closeRequest.emit();
  }

  requestSubmitForm(isCloseModal: boolean = true) {
    this.formSubmitRequest.emit(isCloseModal);
  }

  getClass(color: string) {
    return getStatusLabelClass(color);
  }

  getOrganizationLevels() {
    this.organizationLevels = this.appSettingsStore.levels;
  }

  getUnits(level: number) {
    this.organizationUnitService
      .getOrganizationUnits(level.toString())
      .subscribe({
        next: (data) => {
          runInAction(() => {});
        },
        error: (error) => {
          console.error('Failed to load organization units', error);
        },
      });
  }

  valueChanges() {
    const levels = this.appSettingsStore.levels;
    const organizationLevelsSection: FormSection | undefined =
      this.formSections.find(
        (section) => section.key === 'organization_levels'
      );

    if (!organizationLevelsSection) {
      return;
    }

    levels.forEach((level, index) => {
      const controlName = `OrganizationLevel${level.level}`;
      const nextLevel = levels[index + 1];

      this.formGroup
        .get(controlName)
        ?.valueChanges.pipe(takeUntil(this.destroy$)) // Automatically unsubscribe when destroy$ emits
        .subscribe((selectedUnitIDValue) => {
          if (nextLevel) {
            if (!selectedUnitIDValue) {
              // Clear all subsequent levels
              this.clearNextLevels(index, organizationLevelsSection);
              return;
            }

            // Fetch data for the next level
            this.organizationUnitService
              .getOrganizationUnits(
                `${nextLevel.id}`,
                'title_en',
                'asc',
                undefined,
                false,
                selectedUnitIDValue
              )
              .pipe(takeUntil(this.destroy$)) // Unsubscribe API call when destroy$ emits
              .subscribe({
                next: (data) => {
                  this.populateNextLevelOptions(
                    nextLevel.level,
                    data,
                    organizationLevelsSection
                  );
                  // Clear levels below the next one
                  this.clearNextLevels(index + 1, organizationLevelsSection);
                },
                error: (error) => {
                  console.error(
                    `Failed to load organization units for Level ${nextLevel.level}`,
                    error
                  );
                },
              });
          }
        });
    });
  }

  clearNextLevels(index: number, organizationLevelsSection: FormSection) {
    const levels = this.appSettingsStore.levels;
    levels.slice(index + 1).forEach((nextLevel) => {
      organizationLevelsSection?.fields.forEach((field) => {
        if (field.formControlName === `OrganizationLevel${nextLevel.level}`) {
          field.options = [];
          this.formGroup
            .get(`OrganizationLevel${nextLevel.level}`)
            ?.setValue(null);
        }
      });
    });
  }

  populateNextLevelOptions(
    level: number,
    data: any[],
    organizationLevelsSection: FormSection
  ) {
    organizationLevelsSection?.fields.forEach((field) => {
      if (field.formControlName === `OrganizationLevel${level}`) {
        field.options = data.map((unit) => ({
          id: unit.id,
          title: unit['title_en'],
        }));
        this.formGroup.get(`OrganizationLevel${level}`)?.setValue(null);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(); // Emit value to trigger unsubscription
    this.destroy$.complete();
  }

  // @todo Will be removed later
  // valueChanges() {
  //   const levels = this.appSettingsStore.levels; // Get the levels from the AppSettingsStore

  //   //find organization_levels section from form
  //   const organizationLevelsSection = this.formSections.find(
  //     (section) => section.key === 'organization_levels'
  //   );

  //   // Loop through each organization level to add valueChanges listener
  //   levels.forEach((level, index) => {
  //     const controlName = `OrganizationLevel${level.level}`;
  //     const nextLevel = levels[index + 1]; //next level object

  //     this.formGroup
  //       .get(controlName)
  //       ?.valueChanges.subscribe((selectedUnitIDValue) => {
  //         if (nextLevel) {
  //           // If the current unit ID is empty, clear all form options for the next all dropdowns.
  //           if (!selectedUnitIDValue) {
  //             if (organizationLevelsSection) {
  //               //loop each fields
  //               organizationLevelsSection.fields.forEach((field, index) => {
  //                 const nextLevelId = `organizationLevel${nextLevel.level}`;
  //                 // Set the dynamic id and reset the options array
  //                 if (field.id >= nextLevelId) {
  //                   field.options = []; // Reset options
  //                   // Clear the next level's value
  //                   this.formGroup.get(field.formControlName)?.setValue(null);
  //                 }
  //               });
  //             }
  //             return;
  //           }

  //           // console.log('all levels', toJS(levels));
  //           // console.log('changed value,', currentUnitId);
  //           // console.log('control name', controlName);
  //           // console.log('next level', nextLevel);

  //           //if current level has a value (means changed)
  //           // Get the data for the next level dynamically
  //           this.organizationUnitService
  //             .getOrganizationUnits(
  //               `${nextLevel.id}`,
  //               'title_en',
  //               'asc',
  //               undefined,
  //               false,
  //               selectedUnitIDValue
  //             )
  //             .subscribe({
  //               next: (data) => {
  //                 //find organization_levels section from form

  //                 organizationLevelsSection?.fields.forEach((field) => {
  //                   //get next level form controller
  //                   if (
  //                     field.formControlName ===
  //                     `OrganizationLevel${nextLevel.level}`
  //                   ) {
  //                     //set dropdown data
  //                     field.options = data.map((unit) => ({
  //                       id: unit.id,
  //                       title: unit[`title_en`],
  //                     }));

  //                     // Clear the next level's value
  //                     this.formGroup
  //                       .get(`OrganizationLevel${nextLevel.level}`)
  //                       ?.setValue(null);
  //                   }

  //                   console.log('index', index);

  //                   // Clear all levels below the next one
  //                   levels.slice(index + 2).forEach((lowerLevel) => {
  //                     if (
  //                       field.formControlName ===
  //                       `OrganizationLevel${lowerLevel.level}`
  //                     ) {
  //                       field.options = [];
  //                       this.formGroup
  //                         .get(`OrganizationLevel${lowerLevel.level}`)
  //                         ?.setValue(null);
  //                       // this.formGroup
  //                       //   .get(`OrganizationLevel${lowerLevel.level}`)
  //                       //   ?.disable();
  //                     }
  //                   });
  //                 });
  //               },
  //               error: (error) => {
  //                 console.error(
  //                   `Failed to load organization units for Level ${nextLevel}`,
  //                   error
  //                 );
  //               },
  //             });
  //         }
  //       });
  //   });
  // }

  // valueChanges() {
  //   // When changing OrganizationLevel1 value
  //   this.formGroup
  //     .get('OrganizationLevel1')
  //     ?.valueChanges.subscribe((value) => {
  //       // Get organization level 2 data
  //       this.organizationUnitService.getOrganizationUnits('2').subscribe({
  //         next: (data) => {
  //           // Search and update options for level 2, clear 3, 4, 5
  //           this.formSections.forEach((section) => {
  //             section.fields.forEach((field) => {
  //               if (field.formControlName === 'OrganizationLevel2') {
  //                 field.options = data.map((unit) => ({
  //                   id: unit.id,
  //                   title: unit[`title_en`],
  //                 }));

  //                 this.formGroup
  //                   .get('OrganizationLevel2')
  //                   ?.setValue(null);
  //               }

  //               // Clear lower levels
  //               if (
  //                 field.formControlName === 'OrganizationLevel3' ||
  //                 field.formControlName === 'OrganizationLevel4' ||
  //                 field.formControlName === 'OrganizationLevel5'
  //               ) {
  //                 field.options = [];
  //                 this.formGroup.get('OrganizationLevel3')?.setValue(null);
  //                 this.formGroup.get('OrganizationLevel4')?.setValue(null);
  //                 this.formGroup.get('OrganizationLevel5')?.disable();
  //               }
  //             });
  //           });
  //         },
  //         error: (error) => {
  //           console.error(
  //             'Failed to load organization units for Level 2',
  //             error
  //           );
  //         },
  //       });
  //     });

  //   // When changing OrganizationLevel2 value
  //   this.formGroup
  //     .get('OrganizationLevel2')
  //     ?.valueChanges.subscribe((value) => {
  //       // Get organization level 3 data
  //       this.organizationUnitService.getOrganizationUnits('3').subscribe({
  //         next: (data) => {
  //           // Search and update options for level 3, clear 4, 5
  //           this.formSections.forEach((section) => {
  //             section.fields.forEach((field) => {
  //               if (field.formControlName === 'OrganizationLevel3') {
  //                 field.options = data.map((unit) => ({
  //                   id: unit.id,
  //                   title: unit[`title_en`],
  //                 }));

  //                 this.formGroup
  //                   .get('OrganizationLevel3')
  //                   ?.setValue(null);
  //               }

  //               // Clear lower levels
  //               if (
  //                 field.formControlName === 'OrganizationLevel4' ||
  //                 field.formControlName === 'OrganizationLevel5'
  //               ) {
  //                 field.options = [];
  //                 this.formGroup.get('OrganizationLevel4')?.setValue(null);
  //                 this.formGroup.get('OrganizationLevel5')?.disable();
  //               }
  //             });
  //           });
  //         },
  //         error: (error) => {
  //           console.error(
  //             'Failed to load organization units for Level 3',
  //             error
  //           );
  //         },
  //       });
  //     });
  // }
}
